const defaultNavbarContent = {
    companyName: "Instablogger",
    logoColor: "#000000",
    logoTextSize: "28",
    logoImage: null,
    menuItemColor: "#000000",
    backgroundColor: "#ffffff",
    pageBackgroundColor: "#ffffff",
    textColor: "#000000",
    showCTAButton: true,
    ctaButtonText: "Buy Now",
    ctaButtonTextColor: "#ffffff",
    ctaButtonLink: "/buynow",
    ctaButtonColor: "#3b82f6",
    ctaButtonStyle: "rounded",
    isSticky: false,
    menuItems: [
        { id: '1', text: 'Home', link: '/' },
        { id: '2', text: 'Posts', link: '/tags' },
        { id: '3', text: 'About', link: '/about' }
        
    ]
};

export default defaultNavbarContent;