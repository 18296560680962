// Navbar.js
import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useRouter } from 'next/router';

const EditMenu = ({ navbarContent, updateNavbarContent, onClose }) => {
    const [menuWidth, setMenuWidth] = useState(560);
    const dragHandleRef = useRef(null);
    const menuRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [logoError, setLogoError] = useState(null);

    useEffect(() => {
        const handleMouseDown = (e) => {
            e.preventDefault();
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };

        const handleMouseMove = (e) => {
            const newWidth = window.innerWidth - e.clientX;
            setMenuWidth(Math.max(280, Math.min(newWidth, window.innerWidth * 0.8)));
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        const dragHandle = dragHandleRef.current;
        if (dragHandle) {
            dragHandle.addEventListener('mousedown', handleMouseDown);
        }

        return () => {
            if (dragHandle) {
                dragHandle.removeEventListener('mousedown', handleMouseDown);
            }
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(navbarContent.menuItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateNavbarContent('menuItems', items);
    };

    const addMenuItem = () => {
        const newItem = { id: Date.now().toString(), text: 'New Item', link: '/' };
        updateNavbarContent('menuItems', [...navbarContent.menuItems, newItem]);
    };

    const removeMenuItem = (id) => {
        updateNavbarContent('menuItems', navbarContent.menuItems.filter(item => item.id !== id));
    };

    const updateMenuItem = (id, field, value) => {
        const updatedItems = navbarContent.menuItems.map(item => 
            item.id === id ? { ...item, [field]: value } : item
        );
        updateNavbarContent('menuItems', updatedItems);
    };

    const handleLogoUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        if (!file.type.startsWith('image/')) {
            setLogoError('Please upload an image file.');
            return;
        }
    
        setIsUploading(true);
        setLogoError(null);
    
        try {
            // Convert file to base64
            const base64Image = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
            });
    
            const response = await fetch('https://us-central1-seopublishpro-e6f5b.cloudfunctions.net/callable-upload_image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        image: base64Image,
                        fileName: file.name
                    }
                }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const data = await response.json();

            console.log('data: ', data);
            
            // Adjust this based on how your API returns the image URL
            const imageUrl = data?.result?.imageUrl || data.url || data.link;
            
            if (!imageUrl) {
                throw new Error('Image URL not found in response');
            }
    
            updateNavbarContent('logoImage', imageUrl);
        } catch (error) {
            console.error('Logo upload failed:', error);
            setLogoError(error.message);
        } finally {
            setIsUploading(false);
        }
    };

    const removeLogo = () => {
        updateNavbarContent('logoImage', '');
        setLogoError(null);
    };

    return (
        <div 
            ref={menuRef}
            className="fixed z-50 right-0 top-0 h-full bg-gray-100 shadow-2xl overflow-y-auto transition-all duration-300 ease-in-out"
            style={{ width: `${menuWidth}px` }}
        >
            <div 
                ref={dragHandleRef}
                className="absolute left-0 top-0 bottom-0 w-4 bg-gray-100 cursor-pointer hover:bg-gray-200 hover:bg-gray-400 transition-colors duration-200 flex items-center justify-center"
            >
                <div className="w-1 h-8 bg-gray-500"></div>
            </div>
            <div className="p-6">
                <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Navigation</h2>
                <div className="space-y-6">
                    {/* Logo Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">Logo</h3>
                        <div className="flex items-center space-x-4 mb-3">
                            <input 
                                type="text" 
                                value={navbarContent.companyName} 
                                onChange={(e) => updateNavbarContent('companyName', e.target.value)}
                                className="flex-grow p-2 border rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                                placeholder="Company Name"
                            />
                            <input 
                                type="color" 
                                value={navbarContent.logoColor} 
                                onChange={(e) => updateNavbarContent('logoColor', e.target.value)}
                                className="h-10 w-10"
                            />
                        </div>
                        <div className="flex items-center space-x-4 mb-3">
                            <label className="text-sm font-medium text-gray-700">Logo Text Size:</label>
                            <input 
                                type="number" 
                                value={navbarContent.logoTextSize} 
                                onChange={(e) => updateNavbarContent('logoTextSize', e.target.value)}
                                className="w-20 p-2 border rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                                min="10"
                                max="48"
                            />
                        </div>
                        <div className="mt-3">
                            <input 
                                type="file" 
                                accept="image/*" 
                                onChange={handleLogoUpload}
                                className="mt-1"
                            />
                            {isUploading && (
                                <div className="mt-2">
                                    <svg className="animate-spin h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span className="ml-2">Uploading...</span>
                                </div>
                            )}
                            {logoError && (
                                <p className="mt-2 text-sm text-red-600">{logoError}</p>
                            )}
                        </div>
                        {navbarContent.logoImage && (
                            <div className="mt-2">
                                <img 
                                    src={navbarContent.logoImage} 
                                    alt="Logo preview" 
                                    width={navbarContent.logoImageSize || 100} 
                                    height={navbarContent.logoImageSize || 100} 
                                    className="mb-2" 
                                />
                                <button onClick={removeLogo} className="bg-red-500 text-white px-2 py-1 rounded">Remove Logo</button>
                            </div>
                        )}
                        <div className="flex items-center space-x-4 mt-3">
                            <label className="text-sm font-medium text-gray-700">Logo Image Size:</label>
                            <input 
                                type="number" 
                                value={navbarContent.logoImageSize || 40} 
                                onChange={(e) => updateNavbarContent('logoImageSize', parseInt(e.target.value))}
                                className="w-20 p-2 border rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                                min="20"
                                max="100"
                            />
                        </div>
                    </div>

                    {/* Menu Items Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">Menu Items</h3>
                        <div className="mb-3">
                            <label className="block text-sm font-medium text-gray-700">Menu Item Color</label>
                            <input 
                                type="color" 
                                value={navbarContent.menuItemColor} 
                                onChange={(e) => updateNavbarContent('menuItemColor', e.target.value)}
                                className="mt-1 h-10 w-10"
                            />
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="menu-items">
                                {(provided) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                                        {navbarContent.menuItems.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided) => (
                                                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="flex items-center space-x-2">
                                                        <input 
                                                            type="text" 
                                                            value={item.text} 
                                                            onChange={(e) => updateMenuItem(item.id, 'text', e.target.value)}
                                                            className="flex-grow p-2 border rounded"
                                                        />
                                                        <input 
                                                            type="text" 
                                                            value={item.link} 
                                                            onChange={(e) => updateMenuItem(item.id, 'link', e.target.value)}
                                                            className="flex-grow p-2 border rounded"
                                                        />
                                                        <button onClick={() => removeMenuItem(item.id)} className="text-red-500">X</button>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <button onClick={addMenuItem} className="mt-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">Add Item</button>
                    </div>

                    {/* CTA Button Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">CTA Button</h3>
                        <label className="flex items-center space-x-3 mb-3">
                            <input 
                                type="checkbox" 
                                checked={navbarContent.showCTAButton} 
                                onChange={(e) => updateNavbarContent('showCTAButton', e.target.checked)}
                                className="w-5 h-5 text-gray-500 border-gray-300 rounded focus:ring-gray-500"
                            />
                            <span className="text-gray-700">Show CTA Button</span>
                        </label>
                        {navbarContent.showCTAButton && (
                            <>
                                <input 
                                    type="text" 
                                    value={navbarContent.ctaButtonText} 
                                    onChange={(e) => updateNavbarContent('ctaButtonText', e.target.value)}
                                    className="w-full p-2 border rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent mb-3"
                                    placeholder="CTA Button Text"
                                />
                                <input 
                                    type="text" 
                                    value={navbarContent.ctaButtonLink} 
                                    onChange={(e) => updateNavbarContent('ctaButtonLink', e.target.value)}
                                    className="w-full p-2 border rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent mb-3"
                                    placeholder="e.g., /contact or https://example.com"
                                />
                                <div className="flex items-center space-x-2 mb-3">
                                    <label className="text-sm font-medium text-gray-700">Button Color:</label>
                                    <input 
                                        type="color" 
                                        value={navbarContent.ctaButtonColor} 
                                        onChange={(e) => updateNavbarContent('ctaButtonColor', e.target.value)}
                                        className="h-10 w-10"
                                    />
                                </div>
                                <div className="flex items-center space-x-2 mb-3">
                                    <label className="text-sm font-medium text-gray-700">Button Text Color:</label>
                                    <input 
                                        type="color" 
                                        value={navbarContent.ctaButtonTextColor} 
                                        onChange={(e) => updateNavbarContent('ctaButtonTextColor', e.target.value)}
                                        className="h-10 w-10"
                                    />
                                </div>
                                <div className="flex items-center space-x-2">
                                    <label className="text-sm font-medium text-gray-700">Button Style:</label>
                                    <select
                                        value={navbarContent.ctaButtonStyle}
                                        onChange={(e) => updateNavbarContent('ctaButtonStyle', e.target.value)}
                                        className="p-2 border w-40 rounded focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                                    >
                                        <option value="rounded">Rounded</option>
                                        <option value="square">Square</option>
                                    </select>
                                </div>
                            </>
                        )}
                    </div>

                    {/* Sticky Navbar Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">Sticky Navbar</h3>
                        <label className="flex items-center space-x-3">
                            <input 
                                type="checkbox" 
                                checked={navbarContent.isSticky} 
                                onChange={(e) => updateNavbarContent('isSticky', e.target.checked)}
                                className="w-5 h-5 text-gray-500 border-gray-300 rounded focus:ring-gray-500"
                            />
                            <span className="text-gray-700">Enable Sticky Navbar</span>
                        </label>
                    </div>

                    {/* Navbar Background Color Section */}
                    <div className="bg-white p-4 rounded-lg shadow">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">Navbar Background</h3>
                        <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-700">Background Color:</label>
                            <input 
                                type="color" 
                                value={navbarContent.backgroundColor} 
                                onChange={(e) => updateNavbarContent('backgroundColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                    </div>

                    {/* Page Background Color Section */}
                    <div className="bg-white p-4 rounded-lg shadow mt-4">
                        <h3 className="font-semibold mb-3 text-lg text-gray-700">Page Background</h3>
                        <div className="flex items-center space-x-2">
                            <label className="text-sm font-medium text-gray-700">Background Color:</label>
                            <input 
                                type="color" 
                                value={navbarContent.pageBackgroundColor} 
                                onChange={(e) => updateNavbarContent('pageBackgroundColor', e.target.value)}
                                className="h-8 w-8"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Navbar = ({ editMode, navbarContent, updateNavbarContent }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const router = useRouter();

    useEffect(() => {
        const handleMessage = async (event) => {
            const message = event.data;
            if (message.type === 'SAVE_CHANGES') {
                console.log('Received SAVE_CHANGES message:', message);
                setShowEditMenu(false);
                setIsHovered(false);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        if (navbarContent.isSticky) {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [navbarContent.isSticky]);

    const navbarClasses = `mx-auto transition-all duration-300 
        ${navbarContent.isSticky ? 'fixed top-0 left-0 right-0 z-50 max-w-[1200px]' : ''}
        ${navbarContent.isSticky && isScrolled
            ? 'py-1 sm:py-2 max-w-[90%] sm:max-w-[1000px] lg:max-w-[1200px]'
            : 'py-3 sm:py-4 max-w-[1000px] lg:max-w-[1200px]'}
        ${editMode && !showEditMenu ? 'group' : ''}`;

    const getButtonStyles = () => {
        if (navbarContent.isSticky && isScrolled) {
            return {
                backgroundColor: 'white',
                color: 'black',
            };
        }
        return {
            backgroundColor: navbarContent.ctaButtonColor,
            color: navbarContent.ctaButtonTextColor,
        };
    };

    const getButtonClasses = () => {
        let classes = `transition-all duration-300 ${
            navbarContent.isSticky && isScrolled
                ? 'px-2 py-1 text-xs'
                : 'px-3 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm'
            } font-medium hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2`;

        if (navbarContent.ctaButtonStyle === 'rounded') {
            classes += ' rounded-xl';
        }

        return classes;
    };

    const getLogoStyles = () => {
        if (navbarContent.isSticky && isScrolled) {
            return {
                color: 'white',
                fontSize: '18px'
            };
        }
        return {
            color: navbarContent.logoColor,
            fontSize: `${navbarContent.logoTextSize}px`
        };
    };

    const getMenuItemStyles = () => {
        if (navbarContent.isSticky && isScrolled) {
            return {
                color: 'white'
            };
        }
        return {
            color: navbarContent.menuItemColor
        };
    };

    return (
        <div className={`w-full relative`}>
            <nav
                className={navbarClasses}
                onMouseEnter={() => editMode && !showEditMenu && setIsHovered(true)}
                onMouseLeave={() => editMode && !showEditMenu && setIsHovered(false)}
                style={{
                    backgroundColor: isScrolled ? 'black' :navbarContent.backgroundColor || 'transparent'
                }}
            >
                <div className={`px-4 sm:px-6 ${editMode && !showEditMenu && isHovered ? 'blur' : ''} transition-all duration-300`}>
                    <div className={`flex items-center justify-between ${
                        navbarContent.isSticky && isScrolled ? 'h-8 sm:h-8' : 'h-10 sm:h-12'
                    }`}>
                        <div className="flex items-center space-x-4">
                            <div onClick={() => {
                                window.parent.postMessage({ type: 'LOADING' }, '*');
                                router.push("/");
                            }}>
                                {navbarContent.logoImage ? (
                                    <img 
                                        src={navbarContent.logoImage} 
                                        alt="Logo preview" 
                                        width={navbarContent.logoImageSize || 40} 
                                        height={navbarContent.logoImageSize || 40} 
                                        className="mb-2" 
                                    />
                                ) : (
                                    <span 
                                        className={`font-bold transition-all duration-300 cursor-pointer hover:opacity-80`}
                                        style={getLogoStyles()}
                                    >
                                        {navbarContent.companyName}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center space-x-4 sm:space-x-6">
                            {navbarContent.menuItems.map((item) => (
                                <div onClick={() => {
                                    if (window) {
                                        window.parent.postMessage({ type: 'ROUTED', path: "/UserWebsite" }, '*');
                                        router.push(item.link);
                                    }
                                }} key={item.id}>
                                    <span 
                                        className={`transition-all duration-300 hover:opacity-80 font-medium cursor-pointer font-semibold ${
                                            navbarContent.isSticky && isScrolled
                                                ? 'text-white text-xs sm:text-sm'
                                                : 'text-sm sm:text-base'
                                        }`}
                                        style={getMenuItemStyles()}
                                    >
                                        {item.text}
                                    </span>
                                </div>
                                
                            ))}
                            {navbarContent.showCTAButton && (
                                <div
                                onClick={() => {
                                    if (window) {
                                        window.open(navbarContent.ctaButtonLink, "_blank");
                                    }
                                }}>
                                    <button 
                                        className={getButtonClasses()}
                                        style={getButtonStyles()}
                                    >
                                        {navbarContent.ctaButtonText}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {editMode && !showEditMenu && (
                    <div className={`absolute inset-0 flex items-center justify-center transition-opacity duration-300 ${isHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                        <button
                            onClick={() => setShowEditMenu(true)}
                            className="bg-black text-white px-2 py-1 hover:bg-opacity-75 transition duration-300 ease-in-out flex items-center space-x-2 text-sm z-10"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                            <span>Edit Navbar</span>
                        </button>
                    </div>
                )}
            </nav>
            {showEditMenu && (
                <EditMenu
                    navbarContent={navbarContent}
                    updateNavbarContent={updateNavbarContent}
                    onClose={() => {
                        setShowEditMenu(false);
                        setIsHovered(false);
                    }}
                />
            )}
        </div>
    );
};

export default Navbar;